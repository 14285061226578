import React from 'react';
import Upper from '../UpperFooter/Upper';
import Lower from '../LowerFooter/Lower';

export default function Footer() {
  return (
    <div>
        <Upper />
        <Lower />

    </div>
  )
}
